body {
  /* background: transparent; */
  scroll-behavior: smooth;
  margin: 0;
  font-family: Sans, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html{
  scroll-behavior: smooth;
  /* width: 100vw */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li {
  list-style-type: none;
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%232E3192" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}


@font-face {
  font-family: "Amsterdam Personal Use";
  src:  url("../fonts/Amsterdam Personal Use.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

button.slick-arrow.slick-prev {
  display: none !important;
}

button.slick-arrow.slick-next {
  display: none !important;
}

/* input[type=number] {
  -moz-appearance: textfield;
} */

/* To make number input arrows disappear */
input::-webkit-outer-spin-button,
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* To disable div  */
div[disabled] {
  opacity: 0.6;
  pointer-events: none;

}



/* To remove the date default placeholder  */
input[type=date]::-webkit-inner-spin-button,
  input[type=date]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input[type=date] {
    -moz-appearance: textfield;
  }
