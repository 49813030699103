@tailwind base;

@layer base {
    h1 {
      @apply text-2xl font-bold;
    }
    h2 {
      @apply text-xl;
    }
    h3 {
      @apply text-lg;
    }
    a {
      @apply text-blue-600 underline;
    }
  }

@tailwind components;
@tailwind utilities;


